import gsap from "gsap"

export default class Loader {

  constructor (callback) {
    this.el = document.querySelector('.js-loader')
    this.box = this.el.querySelector('.loader__box')
    this.box2 = this.el.querySelector('.loader__box-2')
    this.boxInner = this.el.querySelector('.loader__box-inner')
    this.icon = this.el.querySelector('.loader__icon')
    this.tl = null
    this.tl2 = null
    this.isLoaded = false

    this.callback = callback
    
    this.init()
  }

  init () {

    gsap.set(this.box, { scale: 0.8, opacity: 0 })
    gsap.set(this.boxInner, { y: '100%' })

    this.tl = gsap.timeline({ repeat: -1, repeatRefresh: true })

    this.tl
      .to( this.box, { duration: 0.6, scale: 1, opacity: 1 }, 0 )
      .fromTo( this.boxInner, { x: '0', y: '100%' }, { duration: 0.6, y: 0 },  0 )
      .to( this.box, { duration: 0.6, scale: 0.8, opacity: 0 }, 0.6 )
      .fromTo( this.boxInner, { x: 0, y: 0 }, { duration: 0.6, y: '-100%' }, 0.6 )

      .to( this.box, { duration: 0.6, scale: 1, opacity: 1 }, 1.2 )
      .fromTo( this.boxInner, { x: '-100%', y: 0 }, { duration: 0.6, x: 0 },  1.2 )
      .to( this.box, { duration: 0.6, scale: 0.8, opacity: 0 }, 1.8 )
      .fromTo( this.boxInner, { x: 0, y: 0 }, { duration: 0.6, x: '100%', onComplete: () => {

        if (!this.isLoaded) return;

        this.tl.pause()
        this.tl.kill()
        this.el.classList.add('is-loaded')
        this.callback()

      } }, 1.8 )
  }

  complete () {
    this.isLoaded = true
  }

}

