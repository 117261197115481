import gsap, { Back, Power3 } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class MScreenAnimation {

  constructor () {
    this.el = document.querySelector('.js-m-screen')
    this.star = this.el.querySelector('.m-screen__star')
    this.line = this.el.querySelector('.m-screen__line')
    this.hero = this.el.querySelector('.m-screen__hero')
    this.description = this.el.querySelector('.m-screen__description')
    this.counter = this.el.querySelector('.m-screen__counter')
    this.circle = this.el.querySelector('.m-screen__scene-bg')
    this.person = this.el.querySelector('.m-screen__scene-man')
    this.btn = this.el.querySelector('.m-screen__btn')

    this.tl = null
    this.mm = null
  }

  init () {

    const el = document.querySelector('.js-horizontal-scroll')

    this.mm = gsap.matchMedia();

    this.mm.add('(min-width: 1025px)', () => {
      
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          scroller: document.body.classList.contains('mobile') ? window : '.smooth-scroll',
          start: 'top top',
          end: () => `+=${window.innerWidth * 2}`,
          scrub: true,
          invalidateOnRefresh: true
        }
      })
  
      this.tl
        .to(this.hero, { x: '-50%' }, 0)
        .to(this.line, { x: '-200px' }, 0)
        .to(this.description, { x: '-70%' }, 0)
        .to(this.star, { x: '50%' }, 0)
        .to(this.counter, { x: '-100%' }, 0)
        .to(this.circle, { x: '-50%' }, 0)
        // .to(this.person, { x: '-30%', ease: Back.easeInOut.config(1) }, 0)
        .to(this.btn, { x: '-100%', ease: Back.easeInOut.config(1) }, 0)

    })


  }

  destroy () {
    if (this.tl) {
      this.tl.kill()
      this.tl = null
    }

    if (this.mm) {
      this.mm.kill()
      this.mm = null
    }
  }

}
