function copyShareLink () {
  let timeout = null
  
  $(document).on('click', '.js-share-link', e => {
    e.preventDefault()
    const target = e.currentTarget
    const link = target.getAttribute('href')
    const container = target.closest('.js-share')
    const links = container.querySelectorAll('.js-share-link')

    navigator.clipboard.writeText(link)

    clearTimeout(timeout)

    links.forEach(el => {
      el.classList.remove('active')
    });

    target.classList.add('active')

    timeout = setTimeout(() => {
      target.classList.remove('active')
    }, 3000)
  })

}

export { copyShareLink }
