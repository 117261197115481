function checkActiveLink (links) {
  for (let i = 0; i < links.length; i++) {
    const link = links[i];

    // Clean class
    link.classList.remove('active');

    // Active link
    if (link.href === location.href) {
      link.classList.add('active');
    }
  }
}

export { checkActiveLink }