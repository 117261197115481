export default class YtVideo {
  constructor (el) {
    this.el = el
    this.playerEl = this.el.querySelector('.js-yt-video-player')
    this.videoId = this.playerEl.getAttribute('data-video-id')
    this.playButton = this.el.querySelector('.js-yt-play-button')
    this.player = null
    this.customCursor = document.querySelector('.custom-cursor')

    this.onPlayBtnClickHandler = (e) => this.onPlayButtonClick(e)

    this.initDefault()
  }

  initDefault () {
    this.initEvents()
  }

  destroy () {
    this.removeEvents()
  }

  initEvents () {
    this.playButton.addEventListener('click', this.onPlayBtnClickHandler)
  }

  removeEvents () {
    this.playButton.removeEventListener('click', this.onPlayBtnClickHandler)
  }

  onPlayButtonClick () {
    if (!this.player) {
      this.createPlayer()
      this.player.addEventListener('onReady', () => {
        this.hidePreview()
        this.play()
      })
    } else {
      this.hidePreview()
      this.play()
    }
  }

  hidePreview () {
    this.el.classList.add('active')
  }

  showPreview () {
    this.el.classList.remove('active')
  }

  play (e) {
    this.player.playVideo()
  }

  createPlayer () {
    this.player = new YT.Player(this.playerEl, {
      height: '100%',
      width: '100%',
      videoId: this.videoId,
      host: 'https://www.youtube.com',
      events: {
        onStateChange: this.onPlayerStateChange.bind(this)
      },
      playerVars: {
        'origin': window.location.host
      }
    });
  }

  onPlayerStateChange (e) {
    setTimeout( () => {
      if ( e.target.getPlayerState() == 2 || e.target.getPlayerState() == 0 ) {
        this.showPreview()
        this.customCursor.classList.remove('hide')
        this.el.classList.remove('is-playing')
      } else {
        this.customCursor.classList.add('hide')
        this.el.classList.add('is-playing')
      }
    }, 500)
  }

}
