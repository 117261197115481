import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

function initCustomJs ( isFirstLoad, locoScroll, uploadFiles ) {
  console.log('init custom js')

  // load more btn loader animation
  $(document).on('click', '.js-load-btn', (e) => {
    e.currentTarget.classList.add('is-loading')
  })

  const form = document.querySelectorAll('.wpcf7-form')

  if (form.length) {
    if (!isFirstLoad) {
      console.log('wpcf7reinit')
      form.forEach(el => {
        
        try {
          wpcf7.reset(el);
        } 
        catch (ev) {
          wpcf7.init(el);
        }

      })
    }
  }

}

function destroyCustomJs () {
  console.log('destroy custom js')
}

export { initCustomJs, destroyCustomJs }
