import gsap from "gsap"

export default class Badge {
  constructor (el) {
    this.el = el
    this.list = this.el.querySelector('.badge__list')
    this.items = this.el.querySelectorAll('.badge__item')
    this.interval = null

    this.resizeHandler = () => this.onResize()
  }

  init () {
    this.initEvents()
    this.setListWidth()
    this.resetItemsPosition()
  }

  initEvents () {
    window.addEventListener('resize', this.resizeHandler)
  }

  startAnimation () {
    let counter = 0

    this.interval = setInterval(() => {
      let currentIndex = counter
      
      gsap.to(this.items[counter], {
        duration: 0.3,
        ease: 'ease',
        opacity: 0,
        x: '-100%',
        onComplete: () => {
          gsap.set(this.items[currentIndex], {
            opacity: 0,
            x: '100%'
          })
        }
      })

      if (counter !== this.items.length - 1) {
        gsap.to(this.items[counter + 1], {
          duration: 0.3,
          ease: 'ease',
          opacity: 1,
          x: 0
        })
        counter++
      } else {
        gsap.to(this.items[0], {
          duration: 0.3,
          ease: 'ease',
          opacity: 1,
          x: 0
        })
        counter = 0
      }

    }, 3000)
  }

  stopAnimation () {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.resetItemsPosition()
  }

  onResize () {
    this.setListWidth()
  }

  resetItemsPosition () {
    this.items.forEach((el, i) => {
      if (i === 0) {
        gsap.set(el, { x: 0, opacity: 1 })
        return;
      }
      gsap.set(el, { x: '100%', opacity: 0 })
    })
  }

  setListWidth () {
    let width = 0
    this.items.forEach(el => {
      const wrapper = el.querySelector('.badge__item-wrapper')
      if (width < wrapper.offsetWidth) {
        width = wrapper.offsetWidth
      }
    })

    this.list.style.width = width + 'px'
  }

}
