import { CountUp } from "countup.js"

export default class Counter {

  constructor (el) {
    this.el = el
    this.trigger = this.el.querySelector('.counter__caption')
    this.counterEl = this.el.querySelector('.counter__value')
    this.value = this.counterEl.getAttribute('data-count')

    this.counter = null

    this.transitionStartHandler = (e) => this.start(e)
  }

  init () {
    this.initEvents()

    this.counter = new CountUp(this.counterEl, this.value)
  }

  initEvents () {
    this.trigger.addEventListener('transitionstart', this.transitionStartHandler)
  }

  removeEvents () {
    this.trigger.removeEventListener('transitionstart', this.transitionStartHandler)
  }

  start () {
    !this.counter.error ? this.counter.start() : console.error(this.counter.error)
  }

  destroy () {
    this.removeEvents()
    this.counter = null
  }

}
