import gsap, { Back, Power3 } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import Badge from "../../components/badge/badge"

gsap.registerPlugin(ScrollTrigger)

export default class MAboutAnimation {

  constructor () {
    this.el = document.querySelector('.js-m-about')

    this.header = this.el.querySelector('.m-header')
    
    this.badge1 = this.el.querySelector('.m-header__badge .badge')
    this.badge1Point = this.badge1.querySelector('.badge__point')
    this.badge1Content = this.badge1.querySelector('.badge__content')
    this.badge1Wrapper = this.badge1.querySelector('.badge__wrapper')

    this.title = this.el.querySelector('.m-header__title')
    this.titleText = this.el.querySelector('.m-header__title span')

    this.description = this.el.querySelector('.m-about__description')

    this.earth = this.el.querySelector('.m-about__earth-image')

    this.badge2 = this.el.querySelector('.m-about__earth-badge .badge')
    this.badge2Point = this.badge2.querySelector('.badge__point')
    this.badge2Content = this.badge2.querySelector('.badge__content')
    this.badge2Wrapper = this.badge2.querySelector('.badge__wrapper')

    this.benefits = this.el.querySelector('.benefits')

    this.countryBadge = new Badge(this.badge2)

    this.tl = null
    this.tl2 = null

    this.mm = null
    this.tweens = []

    this.items = []

    this.items.push(this.header)
    this.items.push(this.description)
    this.items.push(this.earth)
    this.items.push(this.badge2)
    this.items.push(this.benefits)
  }

  init () {

    this.countryBadge.init()

    const el = document.querySelector('.js-horizontal-scroll')

    this.mm = gsap.matchMedia()

    this.mm.add('(min-width: 1025px)', () => {
      this.items.forEach((el, i) => {
        el.classList.remove('js-st-animate')
      })
      gsap.set(this.badge1Point, { opacity: 0, x: 50 })
      gsap.set(this.badge1Wrapper, { x: 50 })
      gsap.set(this.badge1Content, { x: '100%' })
      
      gsap.set(this.badge2Point, { opacity: 0, x: 50 })
      gsap.set(this.badge2Wrapper, { x: 50 })
      gsap.set(this.badge2Content, { x: '100%' })
      
      gsap.set(this.title, { x: '50', transition: 'none' })
      gsap.set(this.titleText, { x: '100%', transition: 'none' })
  
      gsap.set(this.description, { opacity: 0, x: '20%' })
  
      gsap.set(this.earth, { opacity: 0, scale: 0 })
  
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          scroller: document.body.classList.contains('mobile') ? window : '.smooth-scroll',
          start: () => `+=${window.innerWidth} center`,
          end: () => `+=${window.innerWidth}`,
          scrub: true,
          invalidateOnRefresh: true
        }
      })
  
      this.tl
        .to( this.badge1Point, { opacity: 1, x: 0 }, 0 )
        .to( this.badge1Wrapper, { x: 0 }, 0 )
        .to( this.badge1Content, { x: 0 }, 0 )
        .to( this.title, { x: 0 }, 0 )
        .to( this.titleText, { x: 0 }, 0 )
        .to( this.description, { opacity: 1, x: 0 }, 0 )
        .to( this.earth, { opacity: 1, scale: 1 }, 0 )
      
      this.tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.horizontal-scroll__scroll',
          scroller: document.body.classList.contains('mobile') ? window : '.smooth-scroll',
          start: () => `+=${el.scrollWidth*80/100} center`,
          end: () => `+=${el.scrollWidth*20/100 + 200}`,
          scrub: true,
          invalidateOnRefresh: true,
          onEnter: () => {
            this.benefits.classList.add('active')
            this.countryBadge.stopAnimation()
          },
          onLeaveBack: () => {
            this.benefits.classList.remove('active')
            this.countryBadge.stopAnimation()
          }
        }
      })
  
      this.tl2
        .to( this.badge2Point, { opacity: 1, x: 0 }, 0 )
        .to( this.badge2Wrapper, { x: 0 }, 0 )
        .to( this.badge2Content, { 
          x: 0,
          onComplete: () => {
            this.countryBadge.startAnimation()
          } 
        }, 0 )
    })

    this.mm.add('(max-width: 1024px)', () => {
      this.countryBadge.stopAnimation()

      this.items.forEach((el, i) => {
        el.classList.add('js-st-animate')
        this.tweens[i] = ScrollTrigger.create({
          trigger: el,
          start: () => `top bottom-=100px`,
          once: true,
          onEnter: () => { 
            el.classList.add('active')
            if (el.classList.contains('badge--yellow')) {
              this.countryBadge.stopAnimation()
              this.countryBadge.startAnimation()
            }
          },
          scroller: !document.body.classList.contains('enable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll'
        })
      })

    })

  }

  destroy () {
    if (this.tl) {
      this.tl.kill()
      this.tl = null
    }
    if (this.tl2) {
      this.tl2.kill()
      this.tl2 = null
    }
    if (this.tweens.length) {
      this.tweens.forEach(el => {
        el.kill()
        el = null
      })

      this.tweens = []
    }
    if (this.mm) {
      this.mm.kill()
      this.mm = null
    }
  }

}
