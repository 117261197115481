export default class File {

  constructor (el) {
    this.el = el
    this.input = this.el.querySelector('input')
    this.name = this.el.querySelector('.js-file-name')
    this.size = this.el.querySelector('.js-file-size')

    this.onChangeHandler = (e) => this.onChange(e)

    this.initDefaults()
  }

  initDefaults () {
    this.initEvents()
  }

  initEvents () {
    this.input.addEventListener('change', this.onChangeHandler)
  }

  removeEvents () {
    this.input.removeEventListener('change', this.onChangeHandler)
  }

  onChange (e) {
    this.name.innerHTML = e.target.files[0].name
    this.size.innerHTML = this.humanFileSize(e.target.files[0].size, true)
    this.el.classList.add('active')
  }

  humanFileSize (bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }

  destroy () {
    this.removeEvents()
  }

}
