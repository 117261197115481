import Swiper, { Pagination, Autoplay } from "swiper";
Swiper.use([ Pagination, Autoplay ])

export default class ThankSlider {

  constructor (el) {
    this.el = el
    this.slider = null
  }

  init () {
    this.slider = new Swiper(this.el, {
      slidesPerView: 1,
      spaceBetween: 20,
      grabCursor: true,
      touchStartPreventDefault: false,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true
      },
      autoplay: {
        delay: 4000
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 40
        },
        1025: {
          slidesPerView: 2, 
          spaceBetween: 40
        },
        1280: {
          slidesPerView: 2,
          spaceBetween: 60
        },
        1490: {
          slidesPerView: 2,
          spaceBetween: 100
        }
      }
    })
  }

  destroy () {
    if (this.slider) {
      this.slider.destroy()
      this.slider = null
    }
  }

}
