import Swiper, { Navigation } from "swiper"
Swiper.use([Navigation])

export default class NewsSlider {
  constructor (el) {
    this.el = el
    this.slider = null
    this.prev = this.el.closest('.m-news').querySelector('.swiper-navigation__btn--prev')
    this.next = this.el.closest('.m-news').querySelector('.swiper-navigation__btn--next')
    this.prevMobile = this.el.closest('.m-news__content').querySelector('.swiper-navigation__btn--prev')
    this.nextMobile = this.el.closest('.m-news__content').querySelector('.swiper-navigation__btn--next')
  }

  init () {
    this.initEvents()
    this.onResize()
  }

  initSlider () {
    if (this.slider) return;
    this.slider = new Swiper(this.el, {
      slidesPerView: 'auto',
      spaceBetween: 60,
      touchStartPreventDefault: false,
      navigation: {
        prevEl: this.prevMobile,
        nextEl: this.nextMobile
      },
      breakpoints: {
        1025: {
          spaceBetween: 0,
          navigation: {
            prevEl: this.prev,
            nextEl: this.next
          }
        }
      }
    })
  }

  initEvents () {
    window.addEventListener('resize', e => this.onResize())
  }

  onResize () {
    if (window.innerWidth < 768) {
      this.destroy()
    } else {
      this.initSlider()
    }
  }

  destroy () {
    if (this.slider) {
      this.slider.destroy()
      this.slider = null
    }
  }
}
