import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

// ----- import Swup.js

import Swup from 'swup'
import SwupJsPlugin from '@swup/js-plugin'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupScriptsPlugin from '@swup/scripts-plugin'
import SwupGtmPlugin from '@swup/gtm-plugin'

import pageTransition from "./swup/page-transition"
import { manageScripts } from "./swup/manage-scripts"
import { checkActiveLink } from "./swup/check-active-link"

// ----- import Locomotive Scroll

import LocoScroll from "./global/loco-scroll"

// ----- import components

import Loader from "../blocks/components/loader/loader"
import ScrollAnimate from "./global/scroll-animate"
import Header from "../blocks/modules/header/header"
import NewsSlider from "../blocks/components/news-slider/news-slider"
import Gallery from "../blocks/components/gallery/gallery"
import Textarea from "../blocks/components/textarea/textarea"
import PartnersSLider from "../blocks/components/partners-slider/partners-slider"
import TmSlider from "../blocks/components/tm-slider/tm-slider"
import ThankSlider from "../blocks/components/thank-slider/thank-slider"
import MFSlider from "../blocks/components/mf-slider/mf-slider"
import Benefits2 from "../blocks/components/benefits-2/benefits-2"
import Benefits3 from "../blocks/components/benefits-3/benefits-3"
import Counter from "../blocks/components/counter/counter"
import MScreenAnimation from "../blocks/modules/m-screen/m-screen"
import MAboutAnimation from "../blocks/modules/m-about/m-about"
import CustomCursor from "../blocks/components/custom-cursor/custom-cursor"
import YtVideo from "../blocks/components/yt-video/yt-video"
import BenefitsGrid from "../blocks/components/benefits-grid/benefits-grid"
import Tabs from "./global/tabs"
import File from "../blocks/components/file/file"
import History from "../blocks/components/history/history"
import FSlider from "../blocks/components/f-slider/f-slider"
import Mbg from "../blocks/components/m-bg/m-bg"
import VacancyResponse from "../blocks/components/vacancy-response/vacancy-response"

// ----- import functions

import { checkCookies } from "../blocks/components/cookies/cookies"
import { scrollToId } from "./global/scrollToId"
import { copyShareLink } from "../blocks/components/share/share"
import { initCustomJs, destroyCustomJs } from "./custom/custom"

// ----- import scene

import { TerroristApplication } from "./threejs/src/js/applications/terrorist-application"
import { PlanetsApplication } from "./threejs/src/js/applications/planet-application"

// ----- env

import { BASE_URL } from '../../env-config'

// ----- components instances

let loader = null
let scrollAnimate = null
let header = null
let locoScroll = null
let newsSlider = null
let counter = null
let mScreenAnimation = null
let mAboutAnimation = null
let customCursor = null
let benefitsGrid = null
let mbg = null
let vacancyResponse = null

let scene01 = null
let scene02 = null

let history = []
let fSlider = []
let partnersSlider = []
let tmSlider = []
let thankSlider = []
let mfSlider = []
let galleries = []
let textarea = []
let benefits2 = []
let benefits3 = []
let ytVideo = []
let tabs = []
let files = []

// ----- flags

let isMobile = false
let isFirstLoad = true

function checkMobile () {
  isMobile = /iPad|iPhone|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/.test(navigator.userAgent)

  if(!isMobile && navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel|iPad/.test(navigator.platform)) {
    isMobile = true
  }

  if (!isMobile && window.innerWidth < 1025) {
    isMobile = true
  }

  if (isMobile) {
    document.body.classList.add('mobile')
  }
}

// ----- page elements

const links = document.querySelectorAll('.header__link')

// ----- inject youtube api

const tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
tag.setAttribute('data-swup-ignore-script', true)
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// ----- swup.js

const swup = new Swup({
  plugins: [
    new SwupHeadPlugin(),
    new SwupJsPlugin(pageTransition),
    new SwupBodyClassPlugin(),
    new SwupGtmPlugin(),
    new SwupScriptsPlugin({
      optin: true
    })
  ],
  cache: true,
  animateHistoryBrowsing: true,
  containers: ['#swup', '#header', '.custom-cursor__text', '.js-modal']
});

swup.on('animationOutStart', () => {
  header.closeMenu(true)
  document.body.classList.add('block-scroll')
  if (locoScroll) {
    locoScroll.stop()
  }
})

swup.on('willReplaceContent', () => {
  window.scrollTo(0, 0)
  destroy()
})

swup.on('contentReplaced', () => {
  document.body.classList.add('block-scroll')
  
  if (locoScroll) {
    locoScroll.stop()
  }
  
  checkMobile()
  init()
  customCursor.refresh()
  manageScripts()
  
  scene01.build('scene-container')
  scene01.setObserver('.m-screen__scene')
  scene01.startAnimation()
  
  scene02.build('scene-container-02')
  scene02.setObserver('.m-about__earth-image')
  scene02.startAnimation()
  
  console.log('GTM')
  window.dataLayer.push({
    event: 'VirtualPageview',
    virtualPageURL: window.location.pathname + window.location.search,
    virtualPageTitle: document.title
  });
});

swup.on('animationInDone', () => {
  ScrollTrigger.refresh()
  scrollAnimate.init()
  setTimeout(() => {
    document.body.classList.remove('block-scroll')
    if (locoScroll) {
      locoScroll.start()
    }
  }, 0)
})

// ----- events

let portrait = window.matchMedia("(orientation: portrait)");

window.addEventListener('load', async () => {
  const loaderCompleteHandler = () => loader.complete()

  scene02 = new PlanetsApplication(`${BASE_URL}assets/js/threejs/models/planet_earth_with_lines_02.glb`)
  await scene02.init()
  
  scene01 = new TerroristApplication(`${BASE_URL}assets/js/threejs/models/CS_Man_Anim_05.glb`)
  await scene01.init(loaderCompleteHandler)

})

document.addEventListener('DOMContentLoaded', () => {
  loader = new Loader(onLoad)
  onReady()
})

window.addEventListener('resize', () => {
  onResize()
})

window.onYouTubeIframeAPIReady = () => {
  const ytItems = document.querySelectorAll(".js-yt-video");
  if ( ytItems.length ) {
    ytItems.forEach ( (el, i) => {
      ytVideo[i] = new YtVideo(el)
    })
  }
}

window.addEventListener('unload', () => {
  $('.js-loader').css({
    opacity: 0,
    visibility: 'hidden',
    transition: 'none'
  })
})

window.addEventListener('beforeunload', () => {
  $('.js-loader').css({
    opacity: 1,
    visibility: 'visible',
    transition: 'none'
  })
  $('.horizontal-scroll__inner').css({
    transform: 'none'
  })
  // window.scrollTo(0, 0)
  if (locoScroll) {
    locoScroll.scrollTo(0, {duration: 0, disableLerp: true})
  }
  ScrollTrigger.refresh()
})

function onResize () {
  floatFontSize()
  if (document.body.classList.contains('enable-smooth-scroll') && !isMobile) {
    ScrollTrigger.refresh()
  }
}
if (portrait) {
  if (portrait.addEventListener) {
    portrait.addEventListener("change", function(e) {
      ScrollTrigger.refresh()
    })
  } else {
    portrait.addListener(function(e) {
      ScrollTrigger.refresh()
    })
  }
}


// ----- on window load

const onLoad = () => {

  setTimeout(() => {
    if (locoScroll) {
      locoScroll.start()
    }
    document.body.classList.remove('block-scroll')
  }, 0)

  isFirstLoad = false

  scrollAnimate.init()

  scene01.build('scene-container')
  scene01.setObserver('.m-screen__scene')
  scene01.startAnimation()
  
  scene02.build('scene-container-02')
  scene02.setObserver('.m-about__earth-image')
  scene02.startAnimation()

}

// ----- on document ready. Call once on first time load

function onReady () {
  checkMobile()
  checkActiveLink(links)
  copyShareLink()
  checkCookies()
  scrollToId()

  // ----- init components

  $(document).on('click', '[data-modal-open]', e => {
    e.preventDefault()
    const target = e.target.closest('[data-modal-open]')
    const id = target.getAttribute('data-modal-id')
    const modal = document.querySelector(id)

    if (modal) {
      modal.classList.add('active')
      document.body.classList.add('block-scroll')
    }
  })
  
  $(document).on('click', '[data-modal-close]', e => {
    e.preventDefault()
    const target = e.target
    const modal = target.closest('.js-modal')

    if (modal) {
      modal.classList.remove('active')
      document.body.classList.remove('block-scroll')
    }
  })

  init()

  const customCursorEl = document.querySelector('.js-custom-cursor')
  if (customCursorEl) customCursor = new CustomCursor(customCursorEl)
}

// ----- init components. Call every time after route change

function init () {
  
  if (document.body.classList.contains('enable-smooth-scroll')) {
    if(!locoScroll) {
      locoScroll = new LocoScroll(isMobile)
    }
  }

  if (locoScroll) {
    locoScroll.stop()
  }

  floatFontSize()

  scrollAnimate = new ScrollAnimate()

  const headerElement = document.querySelector('.js-header')
  if (headerElement) {
    header = new Header(headerElement, locoScroll)
    header.init()
  }

  const mScreen = document.querySelector('.js-m-screen')
  if (mScreen) {
    mScreenAnimation = new MScreenAnimation()
    mScreenAnimation.init()
  }
  
  const mAbout = document.querySelector('.js-m-about')
  if (mAbout) {
    mAboutAnimation = new MAboutAnimation()
    mAboutAnimation.init()
  }

  const newsSliderElement = document.querySelector('.js-news-slider')
  if (newsSliderElement) {
    newsSlider = new NewsSlider(newsSliderElement)
    newsSlider.init()
  }

  const galleryItems = document.querySelectorAll('.js-gallery')

  if (galleryItems.length) {
    galleryItems.forEach((el, i) => {
      galleries[i] = new Gallery(el)
      galleries[i].init()
    })
  }

  const textareaEl = document.querySelectorAll('.js-textarea')

  if ( textareaEl.length ) {
    textareaEl.forEach((el, i) => {
      textarea[0] = new Textarea(el)
    })
  }

  const partnersSLiderEl = document.querySelectorAll('.js-partners-slider')

  if (partnersSLiderEl.length) {
    partnersSLiderEl.forEach((el, i) => {
      partnersSlider[i] = new PartnersSLider(el)
      partnersSlider[i].init()
    })
  }
  
  const tmSliderEl = document.querySelectorAll('.js-tm-slider')

  if (tmSliderEl.length) {
    tmSliderEl.forEach((el, i) => {
      tmSlider[i] = new TmSlider(el)
      tmSlider[i].init()
    })
  }
  
  const thankSliderEl = document.querySelectorAll('.js-thank-slider')

  if (thankSliderEl.length) {
    thankSliderEl.forEach((el, i) => {
      thankSlider[i] = new ThankSlider(el)
      thankSlider[i].init()
    })
  }
  
  const mfSliderEl = document.querySelectorAll('.js-mf-slider')

  if (mfSliderEl.length) {
    mfSliderEl.forEach((el, i) => {
      mfSlider[i] = new MFSlider(el)
      mfSlider[i].init()
    })
  }

  const benefits2El = document.querySelectorAll('.js-benefits-2')

  if (benefits2El.length) {
    benefits2El.forEach((el, i) => {
      benefits2[i] = new Benefits2(el)
      benefits2[i].init()
    })
  }
  
  const benefits3El = document.querySelectorAll('.js-benefits-3')

  if (benefits3El.length) {
    benefits3El.forEach((el, i) => {
      benefits3[i] = new Benefits3(el)
      benefits3[i].init()
    })
  }

  const counterEl = document.querySelector('.js-counter')

  if (counterEl) {
    counter = new Counter(counterEl)
    counter.init()
  }

  const benefitsGridEl = document.querySelector('.js-benefits-grid')

  if (benefitsGridEl) {
    benefitsGrid = new BenefitsGrid(benefitsGridEl)
    benefitsGrid.init()
  }

  const tabsItems = document.querySelectorAll('.js-tabs')

  if (tabsItems.length) {
    tabsItems.forEach((el, i) => {
      tabs[i] = new Tabs(el)
    })
  }

  const fileItems = document.querySelectorAll('.js-file')

  if (fileItems.length) {
    fileItems.forEach((el, i) => {
      files[i] = new File(el)
    })
  }
  
  const historyItems = document.querySelectorAll('.js-history')

  if (historyItems.length) {
    historyItems.forEach((el, i) => {
      history[i] = new History(el)
      history[i].init()
    })
  }
  
  const fSliderItems = document.querySelectorAll('.js-f-slider')

  if (fSliderItems.length) {
    fSliderItems.forEach((el, i) => {
      fSlider[i] = new FSlider(el)
      fSlider[i].init()
    })
  }
  
  const mbgItem = document.querySelector('.m-bg')

  if (mbgItem) {
    mbg = new Mbg(mbgItem)
    mbg.init()
  }
  
  const vacancyResponseEl = document.querySelector('.js-vacancy-response')

  if (vacancyResponseEl) {
    vacancyResponse = new VacancyResponse(vacancyResponseEl)
    vacancyResponse.init()
  }

  if (!isFirstLoad) {

    const ytItems = document.querySelectorAll(".js-yt-video");
    if ( ytItems.length ) {
      ytItems.forEach ( (el, i) => {
        ytVideo[i] = new YtVideo(el)
      })
    }

  }

  initCustomJs(isFirstLoad, locoScroll)
}

// -----  destroy components and clear instances. Call every time before route change

function destroy () {

  scene01.dispose()
  scene02.dispose()

  if (header) {
    header.destroy()
    header = null
  }
  
  if (mbg) {
    mbg.destroy()
    mbg = null
  }
  
  if (vacancyResponse) {
    vacancyResponse.destroy()
    vacancyResponse = null
  }

  if (mScreenAnimation) {
    mScreenAnimation.destroy()
    mScreenAnimation = null
  }

  if (mAboutAnimation) {
    mAboutAnimation.destroy()
    mAboutAnimation = null
  }

  if (newsSlider) {
    newsSlider.destroy()
    newsSlider = null
  }

  if (locoScroll) {
    locoScroll.destroy()
    locoScroll.destroyNavigation()
    locoScroll.destroyHorizontalScroll()
    locoScroll = null
  }

  if (galleries.length) {
    galleries.forEach(el => {
      el.destroy()
      el = null
    })

    galleries = []
  }

  if (textarea.length) {
    textarea.forEach(el => {
      el.destroy()
    })
    textarea = []
  }

  if (partnersSlider.length) {
    partnersSlider.forEach(el => {
      el.destroy()
      el = null
    })
    partnersSlider = []
  }
  
  if (tmSlider.length) {
    tmSlider.forEach(el => {
      el.destroy()
      el = null
    })
    tmSlider = []
  }
  
  if (thankSlider.length) {
    thankSlider.forEach(el => {
      el.destroy()
      el = null
    })
    thankSlider = []
  }
  
  if (mfSlider.length) {
    mfSlider.forEach(el => {
      el.destroy()
      el = null
    })
    mfSlider = []
  }

  if (counter) {
    counter.destroy()
    counter = null
  }

  if (benefitsGrid) {
    benefitsGrid.destroy()
    benefitsGrid = null
  }

  if (tabs.length) {
    tabs.forEach(el => {
      el.destroy()
      el = null
    })
    tabs = []
  }
  
  if (files.length) {
    files.forEach(el => {
      el.destroy()
      el = null
    })
    files = []
  }
  
  if (history.length) {
    history.forEach(el => {
      el.destroy()
      el = null
    })
    history = []
  }
  
  if (fSlider.length) {
    fSlider.forEach(el => {
      el.destroy()
      el = null
    })
    fSlider = []
  }

  if (ytVideo.length) {
    ytVideo.forEach(el => {
      el.destroy()
      el = null
    })
    ytVideo = []
  }

  destroyCustomJs()
}

function floatFontSize () {
  if (document.body.classList.contains('float-fz')) {
    const mainWidth = 1920
    const mainFontSize = 16
    let fontSize = null
    if (window.innerWidth > 1024) {
      fontSize = (window.innerWidth / mainWidth) * mainFontSize
    } else {
      fontSize = 16
    }
    document.body.style.fontSize = fontSize + 'px'
  } else {
    document.body.style.fontSize = '16px'
  }
}
