export default class VacancyResponse {

  constructor (el) {
    this.el = el
    this.trigger = document.querySelector('.js-vacancy-response-trigger')
    this.getInTouch = document.querySelector('.get-in-touch')

    this.handler = (e) => this.checkVisibility(e)
  }

  init () {
    this.initEvents()
    this.checkVisibility()
  }

  destroy () {
    this.removeEvents()
    this.getInTouch.classList.remove('shift-top')
  }

  checkVisibility () {
    if (this.trigger.getBoundingClientRect().top < 0) {
      this.el.classList.add('active')
      if (this.el.getBoundingClientRect().bottom < window.innerHeight - 50) {
        this.getInTouch.classList.remove('shift-top')
      } else {
        this.getInTouch.classList.add('shift-top')
      }
    } else {
      this.el.classList.remove('active')
      this.getInTouch.classList.remove('shift-top')
    }
  }

  initEvents () {
    window.addEventListener('scroll', this.handler)
    window.addEventListener('resize', this.handler)
  }

  removeEvents () {
    window.removeEventListener('scroll', this.handler)
    window.removeEventListener('resize', this.handler)
  }

}
