import Swiper, { Scrollbar } from 'swiper'
Swiper.use([Scrollbar])

export default class Benefits3 {
  constructor (el) {
    this.el = el
    this.slider = null
  }

  init () {
    this.slider = new Swiper(this.el, {
      slidesPerView: 'auto',
      spaceBetween: 50,
      freeMode: true,
      watchOverflow: true,
      touchStartPreventDefault: false,
      scrollbar: {
        el: '.swiper-scrollbar'
      },
      breakpoints: {
        768: {
          slidesPerView: 5,
          spaceBetween: 10
        }
      }
    })
  }

  destroy () {
    if (this.slider) {
      this.slider.destroy()
      this.slider = null
    }
  }
}
