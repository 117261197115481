
function scrollToId () {
  
  $(document).on('click', '.js-scroll-to-id', (e) => {
    e.preventDefault()
    const id = e.currentTarget.getAttribute('href')
    const target = document.querySelector(id)
    const header = document.querySelector('.js-header')

    if (!target) return;

    const distance = target.getBoundingClientRect().top + window.pageYOffset - (header.offsetHeight + 40 )
    window.scrollTo({
      top: distance,
      behavior: "smooth"
    })
  })

}

export { scrollToId }
