import Swiper, { Pagination } from 'swiper'
Swiper.use([ Pagination ])

export default class Benefits2 {

  constructor (el) {
    this.el = el
    this.slider = null,
    this.pagination = '.swiper-pagination'

    this.resizeHandler = (e) => this.resize(e)
  }

  init () {
    this.initEvents()
    if (window.innerWidth < 768) {
      this.initSlider()
    }
  }

  initEvents () {
    window.addEventListener('resize', this.resizeHandler)
  }

  removeEvents () {
    window.removeEventListener('resize', this.resizeHandler)
  }

  resize () {
    if (window.innerWidth < 768) {
      this.initSlider()
    } else {
      this.destroySlider()
    }
  }

  initSlider () {
    if (this.slider) return;
    this.slider = new Swiper(this.el, {
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: this.pagination,
        dynamicBullets: true
      }
    })
  }

  destroySlider () {
    if (!this.slider) return;
    this.slider.destroy()
    this.slider = null
  }

  destroy () {
    this.removeEvents()
    this.destroySlider()
  }

}
