function checkCookies () {

  const cookies = document.querySelector('.js-cookies')

  if (!cookies) return;

  const ls = localStorage.getItem('hide_cookies')

  if (!ls) {
    cookies.style.display = 'block'
  } else {
    cookies.style.display = 'none'
  }

  $(document).on('click', '.js-cookies-btn', () => {
    localStorage.setItem('hide_cookies', 'true')
    cookies.style.display = 'none'
  })

}

export { checkCookies }
