import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

import LocomotiveScroll from 'locomotive-scroll'
import Navigation from "../../blocks/components/m-navigation/m-navigation"

export default class LocoScroll {

  constructor (isMobile) {
    this.scroll = null
    this.navigation = null
    this.horizontalScroll = null
    this.hTween = null
    this.hPin = null
    this.st = null
    this.isMobile = isMobile

    this.resizeHandler = (e) => this.onResize(e)

    this.init()
  }

  init () {
    this.initEvents()
    if (!this.isMobile) {
      this.initScroll()
    }

    if (window.innerWidth > 1024) {
      this.initNavigation()
      this.initHorizontalScroll()
    }
  }

  initEvents () {
    window.addEventListener('resize', this.resizeHandler)
  }
  
  removeEvents () {
    window.removeEventListener('resize', this.resizeHandler)
  }

  onResize () {
    if (window.innerWidth > 1024) {
      this.initNavigation()
      this.initHorizontalScroll()
      this.update()
    } else {
      this.destroyNavigation()
      this.destroyHorizontalScroll()
      this.update()
    }
  }

  initScroll () {

    if (this.scroll) return;

    this.scroll = new LocomotiveScroll({
      el: document.querySelector('.smooth-scroll'),
      smooth: true,
      multiplier: 0.8,
      lerp: 0.1,
      scrollFromAnywhere: true,
      tablet: {
        smooth: false
      },
      smartphone: {
        smooth: false
      }
    })
    
    this.scroll.on("scroll", () => {
      if (this.navigation) {
        this.navigation.setActiveElementOnScroll()
        this.navigation.setNavigationPosition(this.scroll.scroll.instance.scroll.y)
      }
      ScrollTrigger.update()
    });

    this.st = ScrollTrigger
    
    this.st.scrollerProxy(".smooth-scroll", {
      scrollTop: (value) => {
        if (!this.scroll) {
          return false
        } else {
          return arguments.length ? this.scroll.scrollTo(value, 0, 0) : this.scroll.scroll.instance.scroll.y;
        }
      }, // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed"
    });

    this.st.addEventListener("refresh", () => {
      if (!this.scroll) return;
      this.scroll.update()
    });
    this.st.refresh();
  }

  initHorizontalScroll () {

    if (this.horizontalScroll) return;

    console.log('init hscroll')

    const el = document.querySelector('.js-horizontal-scroll')
    
    if (el) {
      window.scrollTo(0, 0)
      const inner = el.querySelector('.horizontal-scroll__inner')
      const scroll = el.querySelector('.horizontal-scroll__scroll')
      this.horizontalScroll = true

      this.hTween = gsap.to(scroll, {
        x: () => `${-(el.scrollWidth - document.documentElement.clientWidth)}px`,
        ease: "none",
        scrollTrigger: {
          id: 'hpin',
          scroller: document.body.classList.contains('mobile') ? window : '.smooth-scroll',
          trigger: inner,
          start: "top top",
          end: () => `+=${el.scrollWidth + 100}`,
          scrub: true,
          pin: true,
          invalidateOnRefresh: true,
          anticipatePin: 1
        }
      });

    }
  }

  destroyHorizontalScroll () {

    if (!this.horizontalScroll) return;

    console.log('destroy hscroll')

    let hPin = ScrollTrigger.getById('hpin')
    
    hPin.kill()

    if (this.hTween) {
      this.hTween.kill()
      this.hTween = null
    }

    gsap.set('.horizontal-scroll__scroll', { x: 0 })

    this.horizontalScroll = false

    ScrollTrigger.refresh()

  }

  initNavigation () {
    
    if (this.navigation) return;

    const el = document.querySelector('.js-m-navigation')
    if (el) {
      this.navigation = new Navigation(el)
      this.navigation.init()
    }
  }

  destroyNavigation() {
    if (this.navigation) {
      this.navigation.destroy()
      this.navigation = null
    }
  }

  update () {
    if (this.scroll) {
      this.scroll.update()
    }
  }

  stop () {
    if (this.scroll) {
      this.scroll.stop()
    }
  }

  start () {
    if (this.scroll) {
      this.scroll.start()
    }
  }

  scrollTo (pos, options) {
    if (this.scroll) {
      this.scroll.scrollTo(pos, options)
    }
  }

  destroy () {
    if (this.scroll) {
      this.scroll.destroy()
      this.scroll = null
      document.querySelector('.smooth-scroll').removeAttribute('style')
    }

    this.removeEvents()
  }

}
