import gsap, { Power3 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

export default class Header {
  constructor (el, locoScroll) {
    this.el = el
    this.burger = this.el.querySelector('.js-header-burger')
    this.menu = this.el.querySelector('.js-header-menu')
    this.overlay = this.el.querySelector('.js-header-overlay')
    this.swup = document.querySelector('#swup')

    this.isOpen = false
    this.isAnimate = false

    this.toggleHandler = (e) => this.toggle(e)
    this.scrollHandler = (e) => this.onScroll(e)
    this.locoScrollHandler = (args) => this.onLocoScroll(args)
    this.resizeHandler = (e) => this.onResize(e)

    this.locoScroll = locoScroll
    this.body = document.body
  }

  init () {
    this.initEvents()
    this.setOverlayDefaultState()
  }

  destroy () {
    this.removeEvents()
    this.isOpen = false
    this.isAnimate = false
  }

  initEvents () {
    this.burger.addEventListener('click', this.toggleHandler)
    window.addEventListener('scroll', this.scrollHandler)
    if (this.locoScroll) {
      if (this.locoScroll.scroll) {
        this.locoScroll.scroll.on('scroll', this.locoScrollHandler)
      }
    }
  }

  removeEvents () {
    this.burger.removeEventListener('click', this.toggleHandler)
    window.removeEventListener('scroll', this.scrollHandler)
    if (this.locoScroll) {
      if (this.locoScroll.scroll) {
        this.locoScroll.scroll.off('scroll', this.locoScrollHandler)
      }
    }
  }

  onLocoScroll (args) {
    if (this.body.classList.contains('home') && window.innerWidth > 1024) {
      if (args.scroll.y > window.innerWidth * 2 + 150) {
        this.el.classList.add('disable-intro-delays')
        this.el.classList.add('collapsed')
      } else {
        this.el.classList.remove('collapsed')
      }
    } else {
      if (args.scroll.y > 50) {
        this.el.classList.add('disable-intro-delays')
        this.el.classList.add('collapsed')
      } else {
        this.el.classList.remove('collapsed')
      }
    }
  }

  onScroll (e) {

    if (this.body.classList.contains('home') && window.innerWidth > 1024) {
      if (window.scrollY > window.innerWidth * 2 + 50) {
        this.el.classList.add('disable-intro-delays')
        this.el.classList.add('collapsed')
      } else {
        this.el.classList.remove('collapsed')
      }
    } else {
      if (window.scrollY > 50) {
        this.el.classList.add('disable-intro-delays')
        this.el.classList.add('collapsed')
      } else {
        this.el.classList.remove('collapsed')
      }
    }

  }

  toggle (e) {
    e.preventDefault()

    console.log('toggle')

    if (this.isAnimate) return

    this.isAnimate = true
    this.burger.classList.toggle('active')

    if (!this.isOpen) {
      this.hideContent()
      document.body.classList.add('block-scroll')
    } else {
      this.closeMenu()
    }

    
    gsap.fromTo(this.overlay, 1, 
      {
        x: '-100%'
      },
      {
        x: 0,
        ease: Power3.easeIn,
        onComplete: () => {

          if (!this.isOpen) {
            this.el.classList.add('open')
            this.openMenu()
          } else {
            this.showContent()
          }
          
          gsap.fromTo(this.overlay, 0.5, 
            {
              x: 0
            },
            {
              x: '100%',
              ease: 'ease',
              onComplete: () => {
                this.setOverlayDefaultState()
                this.isAnimate = false
                this.isOpen = !this.isOpen
              }
            }
          )

        }
      }
    )

  }

  openMenu () {
    gsap.set(this.swup, { opacity: 0 })
    gsap.set(this.menu, { visibility: 'visible' })
    gsap.fromTo(this.menu, 0.5, 
      {
        opacity: 1,
        x: -80
      },
      {
        opacity: 1,
        x: 0,
        ease: 'ease',
        onComplete: () => {
          gsap.set(this.swup, { opacity: 1, x: 0 })
        }
      }
    )
  }

  closeMenu (force, disableAnimation) {

    let duration = 1

    if (force) {
      // this.el.classList.remove('open')
      this.burger.classList.remove('active')
    }

    if (disableAnimation) {
      duration = 0
    }
        
    gsap.fromTo(this.menu, duration, 
      {
        opacity: 1,
        x: 0
      },
      {
        opacity: 1,
        x: 80,
        ease: Power3.easeIn,
        onComplete: () => {
          this.el.classList.remove('open')
          if (document.body.classList.contains('block-scroll')) {
            document.body.classList.remove('block-scroll')
          }
          gsap.set(this.menu, { opacity: 0, visibility: 'hidden', transform: 'none' })

          if (force) {
            this.isOpen = false
          }
        }
      }
    )
  }

  hideContent () {
    gsap.fromTo(this.swup, 1, 
      {
        opacity: 1,
        x: 0
      },
      {
        opacity: 0,
        x: 80,
        ease: Power3.easeIn
      }
    )
  }

  showContent () {
    gsap.fromTo(this.swup, 0.5, 
      {
        opacity: 0,
        x: -80
      },
      {
        opacity: 1,
        x: 0,
        ease: 'ease'
      }
    )
  }

  setOverlayDefaultState () {
    gsap.set(this.overlay, { x: '-100%', opacity: 1 })
  }

}
