import Swiper, { Navigation } from "swiper"
Swiper.use([ Navigation ])

export default class Gallery {

  constructor (el) {
    this.el = el
    this.container = this.el.querySelector('.swiper-container')
    this.slider = null
    this.counter = this.el.querySelector('.js-gallery-counter')
    this.description = this.el.querySelector('.js-gallery-description')
    this.slides = this.el.querySelectorAll('.swiper-slide')
    this.next = this.el.querySelector('.gallery__btn--next')
    this.prev = this.el.querySelector('.gallery__btn--prev')
    this.isInitFirstTime = true
  }

  init () {
    if (this.slides.length === 1) {
      this.counter.style.display = 'none'
      const container = this.el.querySelector('[data-cursor-drag]')
      console.log(container)
      if (container) {
        container.removeAttribute('data-cursor-drag')
      }
    }

    this.initSlider()
  }

  initSlider () {
    if (!this.slider) {
      this.slider = new Swiper(this.container, {
        slidesPerView: 1,
        loop: this.slides.length - 1,
        loopAdditionalSlides: this.slides.length,
        observer: true,
        observeParents: true,
        spaceBetween: 20,
        touchStartPreventDefault: false,
        grabCursor: true,
        watchOverflow: true,
        navigation: {
          nextEl: this.next,
          prevEl: this.prev
        },
        breakpoints: {
          1025: {
            spaceBetween: 30
          },
          1440: {
            spaceBetween: 50
          }
        },
        on: {
          afterInit: () => {
            this.setCounter(0)
            this.setDescription(0)
            this.checkVideoActiveSlide(0)
          },
          realIndexChange: () => {
            this.setCounter(this.slider.realIndex)
            this.setDescription(this.slider.realIndex)
            this.checkVideoActiveSlide(this.slider.realIndex)
          },
          slideChangeTransitionStart: () => {
            if (!this.isInitFirstTime) {
              this.stopVideo()
            }
            this.isInitFirstTime = false
          }
        }
      })
    }
  }

  checkVideoActiveSlide (index) {
    const video = this.slides[index].querySelector('.gallery__video')

    if (video) {
      this.el.classList.add('is-video-active-slide')
    } else {
      this.el.classList.remove('is-video-active-slide')
    }
  }

  setCounter (index) {
    const total = this.slides.length.toString().padStart(2, '0')
    const current = (index + 1).toString().padStart(2, '0')
    let strong = document.createElement('strong')
    strong.innerHTML = current
    let span = document.createElement('span')
    span.innerHTML = '/' + total

    this.counter.innerHTML = ''
    this.counter.appendChild(strong)
    this.counter.appendChild(span)
  }

  setDescription (index) {
    if (!this.description) return;
    const description = this.slides[index].getAttribute('data-description')
    this.description.innerHTML = description
  }

  destroySlider () {
    if (this.slider) {
      this.slider.destroy()
      this.slider = null
    }
  }

  stopVideo () {
    const video = this.el.querySelectorAll('.js-yt-video-player')
    video.forEach( el => { 
      if (el.tagName !== 'IFRAME') return;
      el.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*') 
    })
  }

  destroy () {
    this.destroySlider()
  }

}
