import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

export default class Mbg {

  constructor (el) {
    this.el = el
    this.logo = this.el.querySelector('.m-bg__logo')

    this.tl = null
  }

  init () {
    if (!document.body.classList.contains('inner')) return;

    const pBG = document.querySelector('.p-bg')

    if (pBG) {
      this.el.classList.add('hide-logo')
    }

    this.tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        scroller: window,
        start: 'center center',
        end: '+=200',
        scrub: 1
      }
    })

    this.tl.to(this.logo, {
      opacity: 0
    })
  }

  destroy () {
    if (this.tl) {
      this.tl.kill()
      this.tl = null
    }
    gsap.set(this.logo, { opacity: 1 })
    this.el.classList.remove('hide-logo')
  }

}
