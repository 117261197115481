import gsap from "gsap";

export default class CustomCursor {

  constructor (el) {
    this.el = el
    this.items = gsap.utils.toArray('.js-custom-cursor-item')
    this.content = this.el.querySelector('.js-custom-cursor-content')
    this.targets = ['a', 'button', 'input[type=checkbox]', 'input[type=radio]']
    this.videoLinks = document.querySelectorAll('.is-video')
    this.dragTargets = ['[data-cursor-drag]']
    this.nextTarget = document.querySelectorAll('[data-cursor-next]')
    this.prevTarget = document.querySelectorAll('[data-cursor-prev]')
    this.text = this.el.querySelector('.js-custom-cursor-text')
    this.isActive = false
    this.preventGrow = false

    this.onTargetEnterHandler = (e) => this.onTargetEnter(e)
    this.onTargetLeaveHandler = (e) => this.onTargetLeave(e)

    this.onVideoEnterHandler = (e) => this.onVideoEnter(e)
    this.onVideoLeaveHandler = (e) => this.onVideoLeave(e)
    
    this.onDragEnterHandler = (e) => this.onDragEnter(e)
    this.onDragLeaveHandler = (e) => this.onDragLeave(e)

    this.onNextEnterHandler = (e) => this.onNextEnter(e)
    this.onNextLeaveHandler = (e) => this.onNextLeave(e)
    
    this.onPrevEnterHandler = (e) => this.onPrevEnter(e)
    this.onPrevLeaveHandler = (e) => this.onPrevLeave(e)

    this.initDefaults()
  }

  initDefaults () {
    this.initEvents()
  }

  initEvents () {
    window.addEventListener('mousemove', e => this.move(e))
    this.addLinksEvents()
  }

  addLinksEvents () {
    this.targets.forEach(el => {
      const elements = document.querySelectorAll(el)
      elements.forEach(selector => {
        if (selector.closest('[data-cursor-drag]')) return;
        selector.addEventListener('mouseenter', this.onTargetEnterHandler)
        selector.addEventListener('mouseleave', this.onTargetLeaveHandler)
      })
    })
    
    this.dragTargets.forEach(el => {
      const elements = document.querySelectorAll(el)
      elements.forEach(selector => {
        selector.addEventListener('mouseenter', this.onDragEnterHandler)
        selector.addEventListener('mouseleave', this.onDragLeaveHandler)
      })
    })

    this.videoLinks.forEach( el => {
      el.addEventListener('mouseenter', this.onVideoEnterHandler)
      el.addEventListener('mouseleave', this.onVideoLeaveHandler)
    })

    if (this.nextTarget.length) {
      this.nextTarget.forEach(el => {
        el.addEventListener('mouseenter', this.onNextEnterHandler)
        el.addEventListener('mouseleave', this.onNextLeaveHandler)
      })
    }
    
    if (this.prevTarget.length) {
      this.prevTarget.forEach(el => {
        el.addEventListener('mouseenter', this.onPrevEnterHandler)
        el.addEventListener('mouseleave', this.onPrevLeaveHandler)
      })
    }

  }

  removeLinksEvents () {
    this.targets.forEach(el => {
      const elements = document.querySelectorAll(el)
      elements.forEach(selector => {
        if (selector.closest('[data-cursor-drag]')) return;
        selector.removeEventListener('mouseenter', this.onTargetEnterHandler)
        selector.removeEventListener('mouseleave', this.onTargetLeaveHandler)
      })
    })
    this.targets.forEach(el => {
      const elements = document.querySelectorAll(el)
      elements.forEach(selector => {
        selector.removeEventListener('mouseenter', this.onDragEnterHandler)
        selector.removeEventListener('mouseleave', this.onDragLeaveHandler)
      })
    })
    this.videoLinks.forEach( el => {
      el.removeEventListener('mouseenter', this.onVideoEnterHandler)
      el.removeEventListener('mouseleave', this.onVideoLeaveHandler)
    })
    if (this.nextTarget.length) {
      this.nextTarget.forEach(el => {
        el.removeEventListener('mouseenter', this.onNextEnterHandler)
        el.removeEventListener('mouseleave', this.onNextLeaveHandler)
      })
    }
    
    if (this.prevTarget.length) {
      this.prevTarget.forEach(el => {
        el.removeEventListener('mouseenter', this.onPrevEnterHandler)
        el.removeEventListener('mouseleave', this.onPrevLeaveHandler)
      })
    }
  }

  refresh () {
    this.onTargetLeave()
    this.onVideoLeave()
    this.onDragLeave()
    this.onNextLeave()
    this.onPrevLeave()

    this.videoLinks = document.querySelectorAll('.is-video')
    this.removeLinksEvents()
    this.nextTarget = document.querySelectorAll('[data-cursor-next]')
    this.prevTarget = document.querySelectorAll('[data-cursor-prev]')
    this.addLinksEvents()
  }

  move (e) {
    const x = e.clientX
    const y = e.clientY

    if (!this.isActive) {
      this.items.forEach( (el, i) => {
        gsap.set(el, {
          x: x,
          y: y,
          force3D: false
        })
      } )
      gsap.set(this.content, {
        x: x,
        y: y,
        force3D: false
      })
      gsap.to(this.el, { duration: 0.6, opacity: 1 })
      this.isActive = true
    }

    this.items.forEach( (el, i) => {

      const duration = 0.3 + ( 0.04 * i )

      gsap.to(el, {
        duration: duration,
        x: x,
        y: y,
        ease: 'ease',
        force3D: false
      })

    } )

    gsap.to(this.content, {
      duration: 0.3,
      x: x,
      y: y,
      ease: 'ease',
      force3D: false
    })

  }

  onTargetEnter (e) {
    if(this.preventGrow) return;
    this.items.forEach( (el, i) => {

      const duration = 0.3 + ( 0.04 * i )

      gsap.to(el, {
        duration: duration,
        scale: 1.1,
        ease: 'ease',
        force3D: false
      })
      
      gsap.to('.custom-cursor__icon--crosshair', {
        duration: duration,
        scale: 1.2,
        ease: 'ease',
        force3D: false
      })

    } )
  }
  
  onTargetLeave (e) {
    if(this.preventGrow) return;
    this.items.forEach( (el, i) => {

      const duration = 0.3 + ( 0.04 * i )

      gsap.to(el, {
        duration: duration,
        scale: 1,
        ease: 'ease',
        force3D: false
      })

      gsap.to('.custom-cursor__icon--crosshair', {
        duration: duration,
        scale: 1,
        ease: 'ease',
        force3D: false
      })

    } )
  }

  onVideoEnter (e) {
    if (!e.currentTarget.classList.contains('is-active')) return;
    if (e.currentTarget.classList.contains('is-playing')) {
      this.el.classList.add('hide')
    }
    this.el.classList.add('video-mode')
    document.body.classList.add('js-custom-cursor')
    this.items.forEach( (el, i) => {

      const duration = 0.3

      gsap.to(el, {
        duration: duration,
        scale: 2,
        ease: 'ease',
        force3D: false
      })

    } )
  }

  onVideoLeave (e) {
    this.el.classList.remove('hide')
    this.el.classList.remove('video-mode')
    document.body.classList.remove('js-custom-cursor')
    if(this.preventGrow) return;
    this.items.forEach( (el, i) => {

      const duration = 0.3

      gsap.to(el, {
        duration: duration,
        scale: 1,
        ease: 'ease',
        force3D: false
      })

    } )
  }

  onDragEnter () {
    this.preventGrow = true
    this.el.classList.add('drag-mode')
    document.body.classList.remove('js-custom-cursor')

    this.items.forEach( (el, i) => {

      const duration = 0.3

      gsap.to(el, {
        duration: duration,
        scale: 2,
        ease: 'ease',
        force3D: false
      })

    } )
  }

  onDragLeave () {
    this.preventGrow = false
    this.el.classList.remove('drag-mode')

    this.items.forEach( (el, i) => {

      const duration = 0.3

      gsap.to(el, {
        duration: duration,
        scale: 1,
        ease: 'ease',
        force3D: false
      })

    } )
  }

  onNextEnter () {
    this.el.classList.add('next-mode')
  }
  
  onNextLeave () {
    this.el.classList.remove('next-mode')
  }
  
  onPrevEnter () {
    this.el.classList.add('prev-mode')
  }
  
  onPrevLeave () {
    this.el.classList.remove('prev-mode')
  }

}
