export default class Tabs {
  constructor (el) {
    this.el = el
    this.btn = this.el.querySelectorAll('.js-tabs-btn')
    this.item = this.el.querySelectorAll('.js-tabs-item')

    this.isAnimate = false

    this.btnHandler = (e) => this.setActiveTab(e)

    this.initDefaults()
  }

  initDefaults () {
    this.initEvents()
    this.setItemsStyle()
  }

  initEvents () {
    this.btn.forEach(el => {
      el.addEventListener('click', this.btnHandler)
    })
  }

  removeEvents () {
    this.btn.forEach(el => {
      el.removeEventListener('click', this.btnHandler)
    })
  }

  setActiveTab (e) {
    e.preventDefault()

    if (this.isAnimate) return; 

    this.isAnimate = true
    const target = e.currentTarget
    
    this.btn.forEach(el => {
      el.classList.remove('active')
    })

    target.classList.add('active')

    $(this.item).fadeOut(300).promise().done(() => {
      const index = $(target).index()
      $(this.item).eq(index).fadeIn(300)
      this.isAnimate = false
    })
  }

  setItemsStyle () {
    this.item.forEach(el => {
      if (el.classList.contains('active')) {
        el.style.display = 'block'
      } else {
        el.style.display = 'none'
      }
    })
  }

  destroy () {
    this.removeEvents(0)
  }
}

