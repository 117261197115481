import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class BenefitsGrid {

  constructor (el) {
    this.el = el
    this.items = this.el.querySelectorAll('.benefits-grid__item')
    this.btn = this.el.querySelector('.btn')
    this.btnContainer = this.el.querySelector('.benefits-grid__more')
    this.itemsToShow = 6
    this.hiddenItems = []

    this.tl = null

    this.onBtnClickHandler = (e) => this.onBtnClick(e)
  }

  init () {
    this.hideItems()
    this.initEvents()
  }

  initEvents () {
    if (!this.btn) return;
    this.btn.addEventListener('click', this.onBtnClickHandler)
  }

  removeEvents () {
    if (!this.btn) return;
    this.btn.removeEventListener('click', this.onBtnClickHandler)
  }

  onBtnClick (e) {
    e.preventDefault()

    this.btnContainer.style.display = 'none'

    this.hiddenItems.forEach(el => el.classList.add('active'))

    this.tl = gsap.timeline()

    this.hiddenItems.forEach( (el, i) => {
      this.tl.to(el, {
        opacity: 1,
        onStart: () => {
          el.classList.add('animated')
        }
      }, i * 0.3)
    })
  }

  hideItems () {
    if (this.items.length > 6) {
      this.hiddenItems = Array.from(this.items).splice(6)
      this.hiddenItems.forEach(el => {
        el.classList.add('hidden')
        el.classList.remove('js-batch')
      })
    }
  }

  destroy () {
    this.removeEvents()

    if (this.tl) {
      this.tl.kill()
      this.tl = null
    }
  }

}
