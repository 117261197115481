import Swiper, { Scrollbar } from 'swiper'
Swiper.use([Scrollbar])

export default class FSlider {
  constructor (el) {
    this.el = el
    this.slider = null

    this.onResizeHandler = (e) => this.onResize(e)
  }

  init () {
    this.initEvents()
    this.onResize()
  }

  initEvents () {
    window.addEventListener('resize', this.onResizeHandler)
  }

  removeEvents () {
    window.removeEventListener('resize', this.onResizeHandler)
  }

  onResize (e) {
    if (window.innerWidth < 1025) {
      this.initSlider()
    } else {
      this.destroySlider()
    }
  }

  initSlider () {
    if (this.slider) return;
    this.slider = new Swiper(this.el, {
      slidesPerView: 'auto',
      spaceBetween: 16,
      freeMode: true,
      watchOverflow: true,
      touchStartPreventDefault: false,
      scrollbar: {
        el: '.swiper-scrollbar'
      },
      breakpoints: {
        768: {
          spaceBetween: 20
        },
        1025: {
          spaceBetween: 0
        }
      }
    })
  }

  destroySlider () {
    if (this.slider) {
      this.slider.destroy()
      this.slider = null
    }
  }

  destroy () {
    this.destroySlider()
    this.removeEvents()
  }
}
