import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

export default class Navigation {
  constructor (el) {
    this.el = el
    this.value = this.el.querySelector('.js-m-navigation-value')
    this.items = this.el.querySelectorAll('.js-m-navigation-item')
    this.sections = document.querySelectorAll('.js-m-section')
  }

  init () {
    console.log('init navigation')
  }

  setNavigationPosition (y) {

    if (ScrollTrigger.positionInViewport('.m-news', 'center') < 0.5) return;

    gsap.to(this.el, { duration: 0, ease: 'none', y: y + 'px' })
  }

  setActiveElementOnScroll () {
    this.sections.forEach((el, i) => {
      
      let horizontal = true
      
      el.closest('.js-horizontal-scroll') ? horizontal = true : horizontal = false
      
      if (ScrollTrigger.isInViewport(el, 0.5, horizontal) && el.getBoundingClientRect().top >= -el.offsetHeight / 2) {
        this.items[i].classList.add('active')
        this.value.innerHTML = `0${i + 1}`
      } else {
        this.items[i].classList.remove('active')
      }
    })
  }

  destroy () {
    console.log('destroy navigation')
  }
}
