import Swiper, { Navigation } from "swiper";
Swiper.use([ Navigation ])

export default class History {
  constructor (el) {
    this.el = el
    this.sliderEl = this.el.querySelector('.js-history-slider')
    this.next = this.el.querySelector('.swiper-navigation__btn--next')
    this.prev = this.el.querySelector('.swiper-navigation__btn--prev')
    this.slider = null
  }

  init () {
    this.slider = new Swiper(this.sliderEl, {
      slidesPerView: 'auto',
      freeMode: true,
      watchOverflow: true,
      touchStartPreventDefault: false,
      watchSlidesVisibility: true,
      grabCursor: true,
      spaceBetween: 50,
      navigation: {
        prevEl: this.prev,
        nextEl: this.next
      },
      breakpoints: {
        1490: {
          spaceBetween: 142
        },
        1024: {
          spaceBetween: 80
        },
        768: {
          spaceBetween: 80
        }
      }
    })
  }

  destroy () {
    if (this.slider) {
      this.slider.destroy()
      this.slider = null
    }
  }
}
