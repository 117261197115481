function manageScripts () {
  const scripts = document.querySelectorAll('script[data-swup-reload-script]')
  const unuseScripts = document.querySelectorAll('script[data-swup-remove-script]')

  if (unuseScripts.length) {
    unuseScripts.forEach(el => el.remove())
  }

  if (scripts.length) {

    scripts.forEach(el => {
      const script = document.createElement('script')
      script.src = el.src
      script.async = true
      script.setAttribute('data-swup-remove-script', true)
      document.body.append(script)
      el.remove()
    })

  }
}

export { manageScripts }
