import gsap, { Power3 } from 'gsap';

const preloader = document.querySelector('.preloader');
gsap.set(preloader, { x: '-100%' })

function animateIn (next) {
  const container = document.querySelector('#swup');
  gsap.fromTo(container, 
    {
      opacity: 0,
      x: -80
    },
    {
      duration: 0.5,
      opacity: 1,
      x: 0,
      ease: 'ease',
      onComplete: () => { 
        container.style.transform = 'none'
        next()
      }
    }
  )

  gsap.fromTo(preloader, 
    {
      x: 0
    },
    {
      duration: 0.5,
      x: '100%',
      ease: 'ease'
    }
  )
}

function animateOut (next) {
  const container = document.querySelector('#swup');
  gsap.set(preloader, { x: '-100%', opacity: 1, force3D: true })
  gsap.fromTo(container, 
    {
      opacity: 1,
      x: 0
    },
    {
      duration: 1,
      opacity: 0,
      x: 100,
      force3D: false,
      ease: Power3.easeIn,
      onStart: () => {
        const hscroll = document.querySelector('.horizontal-scroll__inner')
        if (hscroll) {
          if (hscroll.style.position === 'fixed') {
            hscroll.style.position = 'relative'
            hscroll.style.transform = 'translateY(' + window.scrollY + 'px)'
          }
        }
      },
      onComplete: () => { container.style.transform = 'none' }
    }
  )
    
  gsap.fromTo(preloader,
    {
      x: '-100%'
    },
    {
      duration: 1,
      x: 0,
      force3D: true,
      ease: Power3.easeIn,
      onComplete: next
    }
  )
}

const pageTransition = [
  {
    from: '(.*)',
    to: '(.*)',
    in: (next, infos) => {
      animateIn(next)
    },
    out: (next, infos) => {
      animateOut(next)
    }
  },
  {
    from: '(.*)',
    to: '/',
    in: (next, infos) => {
      gsap.set(preloader, { yPercent: 0, opacity: 1 })
      setTimeout(() => {
        animateIn(next)
      }, 300)
    },
    out: (next, infos) => {
      animateOut(next)
    }
  }
]

export default pageTransition;
